import {
  GATEWAYS,
  TRANSACTION_TYPES
} from '@app/constants';

import {
  makeFieldNotRequired,
  makeFieldRequired,
  makeFieldVisibleAndNotRequired
} from '@app/features/required_fields_helpers';

function isFieldVisibleAndNotRequired(gateway, transactionType) {
  return gateway === GATEWAYS.girogate && transactionType === TRANSACTION_TYPES.blk;
}

function toggleConsumerRefRequired(gateway, transactionType) {
  if (isFieldVisibleAndNotRequired(gateway, transactionType)) {
    makeFieldVisibleAndNotRequired('wpf_form_consumer_reference');
  } else if (gateway === GATEWAYS.girogate && transactionType !== TRANSACTION_TYPES.blk) {
    makeFieldRequired('wpf_form_consumer_reference');
  } else {
    makeFieldNotRequired('wpf_form_consumer_reference');
  }
}

export function handleConsumerReference(gateway, transactionType) {
  toggleConsumerRefRequired(gateway, transactionType);
}
