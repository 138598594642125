function fieldIdToUnderscore(fieldId) {
  return fieldId.replace('[', '_').replace(']', '');
}

function showAddressAndCustomerTitles() {
  if ($('.billing-address-wrapper').children(':visible').length > 0) {
    $('.billing-title').show();
  }
  if ($('.shipping-address-wrapper').children(':visible').length > 0) {
    $('.shipping-title').show();
  }
  if ($('.customer-info-wrapper').children(':visible').length > 0) {
    $('.customer-title').show();
  }
}

function hideAddressAndCustomerTitles() {
  if ($('.billing-address-wrapper').children(':visible').length === 0) {
    $('.billing-title').hide();
  }
  if ($('.shipping-address-wrapper').children(':visible').length === 0) {
    $('.shipping-title').hide();
  }
  if ($('.customer-info-wrapper').children(':visible').length === 0) {
    $('.customer-title').hide();
  }
}

export function makeFieldRequired(fieldId, hiddenWpfField = false) {
  const id = fieldIdToUnderscore(fieldId);
  const field = $('#' + id);
  const fieldValue = field.val();
  const hasError = field.parent('.form-group').hasClass('has-error');

  $('label[for=' + id + '] abbr').remove();
  $('<abbr title="required">*</abbr>')
    .prependTo($('label[for=' + id + ']'));
  $('#' + id + '.required').attr('required', 'required');

  if (fieldValue && fieldValue.length > 0 && hiddenWpfField && !hasError) {
    $('.' + id).hide();
  } else {
    $('.' + id).show();
  }
}

export function makeFieldNotRequired(fieldId, hiddenWpfField = false) {
  const id = fieldIdToUnderscore(fieldId);
  const field = $('#' + id);
  const fieldValue = field.val();
  const hasError = field.parent('.form-group').hasClass('has-error');

  $('label[for=' + id + '] abbr').remove();

  $('#' + id + '.required').removeAttr('required');

  if(typeof fieldValue === 'undefined' || fieldValue === null) {
    $('.' + id).hide();

    return false;
  }

  if ((fieldValue.length > 0 && !hiddenWpfField) || hasError) {
    $('.' + id).show();

    showAddressAndCustomerTitles();
  } else {
    $('.' + id).hide();

    hideAddressAndCustomerTitles();
  }
}

export function makeFieldVisibleAndNotRequired(fieldId) {
  const id = fieldIdToUnderscore(fieldId);

  $('label[for=' + id + '] abbr').remove();
  $('#' + id + '.required').removeAttr('required');
  $('.' + id).show();

  showAddressAndCustomerTitles();
}
